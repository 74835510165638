<template>
  <div class="all">
    <img src="../assets/homepage/bg1.png" class="imge-back" />
    <Nav class="nav_1"></Nav>
    <div class="text1">
      <p class="text1-p">深圳市沃特沃德软件技术有限公司</p>
      <span class="text1-span1">科技创新引领发展</span>
      <!-- <span class="text1-span2">·</span>
      <span class="text1-span3">产教融合共育技术人才</span> -->
    </div>
    <div class="all2">
      <div>
        <p class="text2">最新资讯</p>
        <ul class="nav">
          <li><a href=""> 集团新闻</a></li>
          <li><a href=""> 媒体报道</a></li>
          <li><a href=""> 行业资讯</a></li>
        </ul>
      </div>

      <div class="all_left">
        <img src="../assets/homepage/little.png" />
        <div class="all_left_div">
          <p class="all_left_text1">要让学生知道，努力学习是一种责任！</p>
          <p class="all_left_text2">2021-05-30</p>
          <p class="all_left_text3">
            中国心理学会法律心理学分会副会长、中国人民公安大学教授李玫瑾，同时也担任中国预防青少年犯罪研究会的副会长...
          </p>
        </div>
      </div>
      <div class="all_right">
        <div
          class="all_right_div1"
          v-for="(item, index) in ObjectList"
          :key="index"
        >
          <p class="all_right_text1">{{ item.title }}</p>
          <p class="all_right_text2">{{ item.date }}</p>
          <p class="all_right_text3">{{ item.content }}</p>
        </div>
      </div>
      <div class="all3">
        <div class="all3_nav">
          <div class="all3_nav_div1">集团产业</div>
          <div class="all3_nav_div2">更多 ></div>
        </div>
        <div class="all3_div1">
          <div
            v-for="(item, index) in imgList"
            :key="index"
            class="all3_div1_div"
          >
            <img :src="item.img1" class="all3_img1" />
            <img :src="item.img2" class="all3_div1_img1" />
            <p class="all3_div1_p1">{{ item.title }}</p>
            <p class="all3_div1_p2">{{ item.nav }}</p>
          </div>
        </div>
        <div class="all3_div2">
          <img src="../assets/homepage/蒙版(16).png" class="all3_img2" />
          <img src="../assets/homepage/矩形.png" class="all3_div1_img2" />
          <p class="all3_div2_p1">职教产业</p>
          <p class="all3_div2_p2">
            校企共建专业 | 校企共建二级学院 | 校企共建产业学院
          </p>
        </div>
      </div>
      <div class="all4">
        <div class="all4_div1">
          <p class="all4_p1">关于企业</p>
          <p class="all4_p2">
            沃特沃德集团，成立于2009年，全球领先的智能终端全方位解决方案提供商，国家级高新技术企业 。产品涵盖移动通讯、智能穿戴、智能家居、汽车电子、物联网、卫星通信移动终端等电子消费产品，以及大数据、云平台等服务。公司为中国科技创新综合实力民营100强企业（2019年列37位）、国家大数据综合试验区重点企业、深圳市知识产权优势企业。
总部位于中国深圳，在深圳、上海、厦门、泉州、遵义和惠州建立研发中心，重点聚焦在大通信、大物联、大智能、集成电路4个领域；在深圳、惠州、遵义和印度新德里建有智能终端生产制造基地，能快速响应全球客户需求。
公司业务已遍及欧洲、美洲、亚洲和非洲等60多个国家和地区，为全球客户及消费者持续提供高性价比的产品与服务。
          </p>
        </div>
        <div class="all4_div2">
          <img src="../assets/homepage/bg3.png" class="all4_img" />
        </div>
        <div class="all4_div3"><p class="all4_div3_p">了解更多</p></div>
        <!-- <div class="all4_div">
          <div class="all4_div4">
            <p class="all4_div4_p1">30 </p>
            <p class="all4_div4_p2">+</p>
            <p class="all4_div4_p3">全国校区</p>
          </div>
      
          <div class="all4_div5">
            <p class="all4_div4_p1">10 </p>
            <p class="all4_div4_p2">+</p>
            <p class="all4_div4_p3">覆盖城市</p>
          </div>
          <div class="all4_div6">
            <p class="all4_div4_p1">7000 </p>
            <p class="all4_div4_p4">+</p>
            <p class="all4_div4_p5">优秀讲师</p>
          </div>
          <div class="all4_div7">
            <p class="all4_div4_p1">80 </p>
            <p class="all4_div4_p2">万</p>
            <p class="all4_div4_p3">服务学生</p>
          </div>
        </div> -->
      </div>
      <!-- <div class="all5"> -->
        <!-- <div class="all5_div1"> -->
          <!-- <div class="all5_div1_div1">合作伙伴</div> -->
          <!-- <div class="all5_div1_div2">
            <div style="display: inline-block">
              <img
                src="../assets/homepage/矩形(1).png"
                class="all5_div1_img1"
              />
            </div>
            <div class="all5_div1_div3">合作院校</div>

            <div style="display: inline-block">
              <img src="../assets/homepage/企业.png" class="all5_div1_img2" />
            </div>
            <div class="all5_div1_div4">合作企业</div>
          </div> -->
          <!-- <div class="all5_div2">
            <img src="../assets/homepage/蒙版(1).png" />
            <img src="../assets/homepage/蒙版(2).png" />
            <img src="../assets/homepage/蒙版(3).png" />
            <img src="../assets/homepage/蒙版(4).png" />
            <img src="../assets/homepage/蒙版(5).png" />
            <img src="../assets/homepage/蒙版(6).png" />
            <img src="../assets/homepage/蒙版(7).png" />
            <img src="../assets/homepage/蒙版(8).png" />
            <img src="../assets/homepage/蒙版(9).png" />
            <img src="../assets/homepage/蒙版(10).png" />
            <img src="../assets/homepage/蒙版(11).png" />
            <img src="../assets/homepage/蒙版(12).png" />
            <img src="../assets/homepage/蒙版(13).png" />
            <img src="../assets/homepage/蒙版(14).png" />
            <img src="../assets/homepage/蒙版(15).png" />         
          </div> -->
        <!-- </div> -->
      <!-- </div> -->
      <!-- <div></div> -->
      <Bottom class="nav_2"></Bottom>
    </div>
  </div>
</template>
<script>
import Nav from "./nav1/index.vue";
import Bottom from "./nav1/bottomnav.vue";
export default {
  name: "HelloWorld",
  props: {},
  components: {
    Nav,
    Bottom
  },
  data() {
    return {
      ObjectList: [
        {
          title: "发展数字经济 共享包容增长 为经济发展插上“数字翅膀”",
          date: "2021-12-12",
          content:
            "中国心理学会法律心理学分会副会长、中国人民公安大学教授李玫瑾，同时也担任中国预防青少年犯罪研究会的副会长...",
        },
        {
          title: "用系统思维下好“职业教育一盘大棋”",
          date: "2021-12-12",
          content:
            "中国心理学会法律心理学分会副会长、中国人民公安大学教授李玫瑾，同时也担任中国预防青少年犯罪研究会的副会长...",
        },
        {
          title: "我国将在全国实施动产和权利担保统一登记—拓融资渠道",
          date: "2021-12-12",
          content:
            "中国心理学会法律心理学分会副会长、中国人民公安大学教授李玫瑾，同时也担任中国预防青少年犯罪研究会的副会长...",
        },
      ],
      imgList: [
        {
          img1: require("../assets/homepage/矩形(3).png"),
          img2: require("../assets/homepage/矩形.png"),
          title: "金融产业",
          nav: "融资租赁 | 债券融资 | 信托 | 产业基金",
        },
        {
          img1: require("../assets/homepage/矩形(5).png"),
          // img:  require("../assets/homepage/矩形(5).png"),
          img2: require("../assets/homepage/矩形.png"),
          // img2: "../assets/homepage/矩形.png",
          title: "科技产业",
          nav: "教育信息化  |  云技术与应用  |  城市信息化",
        },
        {
          img1: require("../assets/homepage/矩形(5).png"),
          // img: "../assets/homepage/矩形(5).png ",
          img2: require("../assets/homepage/矩形.png"),
          // img2: "../assets/homepage/矩形.png",
          title: "电商产业",
          nav: "网店运营 ｜ 创业孵化 ｜ 垂直电商",
        },
        {
          img1: require("../assets/homepage/矩形(7).png"),
          img2: require("../assets/homepage/矩形.png"),
          // img: "../assets/homepage/矩形(7).png ",
          // img2: "../assets/homepage/矩形.png",
          title: "幼教产业",
          nav: "共建专业  |  订单式合作  |  共同项目研发  |  共建学院",
        },
      ],
      // arrList: [
      //   { P1: 30, P2: "+", P3: "全国校区" },
      //   { P1: 10, P2: "+", P3: "覆盖城市" },
      //   { P1: 7000, P2: "+", P3: "优秀讲师" },
      //   { P1: 80, P2: "万", P3: "服务学生" },
      // ],
    };
  },
};
</script>
<style scoped>
@import url("../assets/style.css");
</style>
